import { JobDescription } from "./JobDescription";
import { jobs } from "./data/jobs";

const Experience = () => {
	return (
		<div className="flex flex-col mt-32 lg:mt-56">
			<h4 className="m-auto text-2xl font-extrabold">Working experience</h4>
			<div className="flex flex-col">
				{jobs.map((job, index) => (
					<JobDescription {...job} isEven={!!(index % 2)} />
				))}
			</div>
		</div>
	);
};
export { Experience };
