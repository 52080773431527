const Header = () => (
	<>
		<div className="justify-between flex">
			<div className="join bg-transparent">
				<button className="btn bg-transparent join-item border-none">Experience</button>
				{/* <button className="btn bg-transparent join-item border-none">Projects</button> */}
			</div>
		</div>
	</>
);

export { Header };
