import { ReactElement } from "react";
import Typed from "react-typed";

type CharType = "htmlTag" | "htmlKey" | "reserved" | "quoted" | "text";

export type Content = { animated: boolean; line: string };

const EditorLike = ({ content }: { content: Content[] }) => {
	return (
		<div className="relative flex flex-col rounded-xl border border-slate-500/30 bg-cyan-950  shadow-black max-w-[75%] lg:max-w-none lg:w-fit">
			<WindowBar />
			<div className="relative text-xs flex-auto flex flex-col w-full overflow-auto min-h-0 text-[11px]">
				<pre className="flex  min-h-full ">
					<EditorLines content={content} />
					<EditorContent content={content} />
				</pre>
			</div>
		</div>
	);
};

const WindowBar = () => (
	<div className="flex-none border-b border-slate-500/30">
		<div className="flex items-center h-8 space-x-1.5 px-3">
			<div className="w-2.5 h-2.5 bg-[#FF605C] rounded-full"></div>
			<div className="w-2.5 h-2.5 bg-[#FFBD44] rounded-full"></div>
			<div className="w-2.5 h-2.5 bg-[#00CA4E] rounded-full"></div>
		</div>
	</div>
);

const EditorLines = ({ content }: { content: Content[] }) => (
	<div
		aria-hidden="true"
		className="hidden md:block text-slate-600 flex-none py-4 pr-4 text-right select-none"
		style={{ width: "50px" }}
	>
		{content.map((_value, index) => (
			<>
				{index + 1} <br />
			</>
		))}
	</div>
);

const EditorContent = ({ content }: { content: Content[] }) => {
	const styledLines = content.map(({ animated, line }) => {
		const element = escapeHtml(line, animated);
		return { animated, element };
	});
	return (
		<code className="flex-auto relative  text-slate-50 pt-4 pr-4 max-w-[90vw]  md:max-w-[90vw]  md:block md:text-[11px] lg:overflow-x-clip ">
			{styledLines.map(({ element }) => element)}
		</code>
	);
};

const escapeHtml = (unsafe: string, animated: boolean): ReactElement => {
	const stringLegendMap: Record<number, { value: string; type: CharType }> = {};
	if (animated) {
		return (
			<>
				<Typed loop={true} typeSpeed={60} strings={[unsafe]} contentType={"html"} />
				<br />
			</>
		);
	}
	for (let index = 0; index <= unsafe.length; index++) {
		const char = unsafe.charAt(index);
		if (isPartOfHtmlTag(char)) {
			stringLegendMap[index] = { value: char, type: "htmlTag" };
		} else if (char === '"' || isWithinQuotes(unsafe, index)) {
			stringLegendMap[index] = { value: char, type: "quoted" };
		} else if (isPartOfHtmlKey(char, index, stringLegendMap)) {
			stringLegendMap[index] = { value: char, type: "htmlKey" };
		} else if (isPartOfReserved(char, index, unsafe)) {
			stringLegendMap[index] = { value: char, type: "reserved" };
		} else {
			stringLegendMap[index] = { value: char, type: "text" };
		}
	}

	const returned = Object.entries(stringLegendMap).map(([index, { value, type }]) => {
		if (type === "htmlTag") {
			return (
				<span key={index} className="text-slate-600">
					{value}
				</span>
			);
		} else if (type === "htmlKey") {
			return (
				<span key={index} className="text-[#F92672]">
					{value}
				</span>
			);
		} else if (type === "quoted") {
			return (
				<span key={index} className="text-[#96E072]">
					{value}
				</span>
			);
		} else if (type === "reserved") {
			return (
				<span key={index} className="text-[#FFE66D]">
					{value}
				</span>
			);
		} else {
			return <span key={index}>{value}</span>;
		}
	});

	return (
		<>
			{animated ? returned : returned}
			<br />
		</>
	);
};

const isWithinQuotes = (unsafe: string, index: number): boolean => {
	let withinQuotes = false;
	for (let i = 0; i < index; i++) {
		const char = unsafe.charAt(i);
		if (char === '"') {
			withinQuotes = !withinQuotes;
		}
	}
	return withinQuotes;
};

const isPartOfHtmlTag = (char: string): boolean => {
	return char === "<" || char === ">" || char === "/" || char === "=";
};

const isPartOfHtmlKey = (
	char: string,
	index: number,
	stringLegendMap: Record<number, { value: string; type: CharType }>
): boolean => {
	if (
		char !== " " &&
		(stringLegendMap[index - 1]?.type === "htmlTag" ||
			stringLegendMap[index - 1]?.type === "htmlKey")
	) {
		return true;
	} else {
		return false;
	}
};

const isPartOfReserved = (char: string, index: number, unsafe: string) => {
	const substring = unsafe.substring(index, unsafe.length);
	const firstEqualIndex = substring.indexOf("=");
	const isBlankBetweenCharAndEqual = substring.substring(0, firstEqualIndex).includes(" ");

	return !isBlankBetweenCharAndEqual && firstEqualIndex !== -1;
};

export { EditorLike };
