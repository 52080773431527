import { Job } from "../JobDescription";
import BadgerImage from "../images/badger-logomark-inlay-noborder--medium.png";
import Levelworks from "../images/levelworks.png";

export const jobs: Job[] = [
	{
		companyName: "Badger",
		startDate: new Date(2019, 7, 15),
		endDate: new Date(2021, 11, 31),
		image: BadgerImage,
		description: [
			{
				line: '<div className="w-full flex-auto flex min-h-0 overflow-auto">                                   ',
				animated: false,
			},
			{ line: '	<div className="relative min-h-0 flex-auto flex flex-col">', animated: false },
			{ line: '		<div className="relative min-h-0 flex-auto flex flex-col">', animated: false },
			{
				line: `&emsp;&emsp;&emsp;&emsp;&emsp;During my experience at Badger I worked as a fullstack developer\n&emsp;&emsp;&emsp;&emsp;&emsp;This gave me the opportunity to expeand my knowledge on maps and CRM's\n&emsp;&emsp;&emsp;&emsp;&emsp;Besides that, I found my passion to deliver clean code and follow good principles`,
				animated: true,
			},
			{ line: "		</div>", animated: false },
			{ line: "	</div>", animated: false },
			{ line: "</div>", animated: false },
			{ line: "", animated: false },
			{ line: "", animated: false },
		],
	},
	{
		companyName: "Level.works",
		startDate: new Date(2022, 12, 1),
		endDate: new Date(),
		image: Levelworks,
		description: [
			{
				line: '<div className="w-full flex-auto flex min-h-0 overflow-auto">                                             ',
				animated: false,
			},
			{ line: '	<div className="relative min-h-0 flex-auto flex flex-col">', animated: false },
			{ line: '		<div className="relative min-h-0 flex-auto flex flex-col">', animated: false },
			{
				line: `&emsp;&emsp;&emsp;&emsp;&emsp;During the work at Level.works I've contributed to the development of multiple features.\n&emsp;&emsp;&emsp;&emsp;&emsp;Some of the most interesting parts, can be the integration with external financial services,\n&emsp;&emsp;&emsp;&emsp;&emsp;the delivery of a white label product, and the maintenance and replication\n&emsp;&emsp;&emsp;&emsp;&emsp;of the infrastructure of it.`,
				animated: true,
			},
			{ line: "		</div>", animated: false },
			{ line: "	</div>", animated: false },
			{ line: "</div>", animated: false },
			{ line: "", animated: false },
			{ line: "", animated: false },
			{ line: "", animated: false },
		],
	},
];
