import { Header } from "./Header";
import { AboutMe } from "./AboutMe";
import { Experience } from "./Experience";

function App() {
	return (
		<div className="bg-gradient-to-r from-[#141e30] to-[#243b55]  w-full md:w-full p-3">
			<Header />
			<AboutMe />
			<Experience />
			{/* <Projects /> */}
		</div>
	);
}

export default App;
